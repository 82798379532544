"use strict";
exports.__esModule = true;
var new_co_window_1 = require("./new-co.window");
var new_co_urlnavigator_1 = require("./new.co.urlnavigator");
var ServerResponse;
(function (ServerResponse) {
    var Handler = (function () {
        function Handler(config) {
            this.config = config;
            var data = new ServerResponse.Response.Data();
            data.ok = config.data.ok;
            if (config.data.global != null) {
                var f_1 = {};
                Object.keys(config.data.global).forEach(function (key) {
                    f_1[key] = config.data.global[key];
                });
                data.global = f_1;
            }
            else {
                data.global = null;
            }
            if (config.data.fields == undefined) {
                return;
            }
            var _loop_1 = function (field) {
                var f = {};
                Object.keys(field).forEach(function (key) {
                    f[key] = field[key];
                });
                f.name = field.name;
                f.type = field.type;
                f.show = field.show;
                f.message = field.message;
                f.index = field.index;
                f.querySelector = field.querySelector;
                f.url = field.url;
                f.html = field.html;
                data.fields.push(f);
            };
            for (var _i = 0, _a = config.data.fields; _i < _a.length; _i++) {
                var field = _a[_i];
                _loop_1(field);
            }
            this.config.data = data;
        }
        Handler.getHandlerFunctions = function () {
            return Handler.handlerFunctions;
        };
        Handler.setHandlerFunctions = function (c) {
            this.handlerFunctions = c;
        };
        Handler.prototype.execute = function () {
            this.handleSpecialFieldTypes();
            if (Handler.handlerFunctions) {
                Handler.handlerFunctions.removeFieldResponses(this.config.form);
                Handler.handlerFunctions.removeGlobalResponse(this.config.form);
            }
            if (this.config.data.fields) {
                for (var _i = 0, _a = this.config.data.fields; _i < _a.length; _i++) {
                    var field = _a[_i];
                    if (['success', 'error'].indexOf(field.type) > -1) {
                        if (Handler.handlerFunctions) {
                            Handler.handlerFunctions.addFieldResponse(field, this.config.form);
                        }
                    }
                }
            }
            if (this.config.data.global) {
                if (this.config.data.global) {
                    if (Handler.handlerFunctions) {
                        Handler.handlerFunctions.showGlobalResponse(this.config.data.global, this.config.form);
                    }
                }
            }
            if (this.config.data.ok) {
                if (this.config.data.ok === true) {
                    if (Handler.handlerFunctions) {
                        Handler.handlerFunctions.onSuccess();
                    }
                }
                else {
                    if (Handler.handlerFunctions) {
                        Handler.handlerFunctions.onError();
                    }
                }
            }
            if (Handler.handlerFunctions) {
                Handler.handlerFunctions.onComplete();
            }
            return this;
        };
        Handler.prototype.handleSpecialFieldTypes = function () {
            var that = this;
            if (this.config.data.fields != null) {
                this.config.data.fields.forEach(function (field) {
                    if (field.type == 'html') {
                        if (Handler.handlerFunctions == null || Handler.handlerFunctions.handleHTMLField(field) === false) {
                            $(field.querySelector).html(field.html);
                        }
                        return;
                    }
                    if (field.type == 'remove') {
                        if ($(field.querySelector)) {
                            $(field.querySelector).remove();
                        }
                    }
                    if (field.type == 'redirect') {
                        if (Handler.handlerFunctions.handleRedirectField(field) !== true) {
                            window.location.href = field.url;
                        }
                    }
                    if (field.type == 'close_window') {
                        var w = new_co_window_1.COWindow.getLast();
                        if (w) {
                            w.close();
                        }
                    }
                    if (field.type == 'close_all_window') {
                        new_co_window_1.COWindow.closeAll();
                    }
                    if (field.type == 'center_windows') {
                        new_co_window_1.COWindow.centerAll();
                    }
                    if (field.type == 'reset_form') {
                        if (that.config.form != null) {
                            that.config.form.reset();
                        }
                    }
                    if (field.type == 'reload') {
                        window.location.reload();
                    }
                    if (field.type == 'replace_url') {
                        new_co_urlnavigator_1.COURLNavigator.setURL(field.url);
                    }
                });
            }
        };
        Handler.prototype.isOk = function () {
            return this.config.data.ok;
        };
        Handler.prototype.getField = function (name) {
            for (var _i = 0, _a = this.config.data.fields; _i < _a.length; _i++) {
                var field = _a[_i];
                if (field.name === name) {
                    return field;
                }
            }
            return null;
        };
        Handler.prototype.getForm = function () {
            return this.config.form;
        };
        Handler.handlerFunctions = null;
        return Handler;
    }());
    ServerResponse.Handler = Handler;
    var Response;
    (function (Response) {
        var Data = (function () {
            function Data() {
                this.fields = [];
            }
            return Data;
        }());
        Response.Data = Data;
    })(Response = ServerResponse.Response || (ServerResponse.Response = {}));
    var HandlerFunctions = (function () {
        function HandlerFunctions() {
        }
        return HandlerFunctions;
    }());
    ServerResponse.HandlerFunctions = HandlerFunctions;
})(ServerResponse = exports.ServerResponse || (exports.ServerResponse = {}));
