"use strict";
exports.__esModule = true;
var CODOM = (function () {
    function CODOM() {
    }
    CODOM.ready = function (callback) {
        if (document.readyState != 'complete' && document.readyState != 'interactive') {
            document.addEventListener('DOMContentLoaded', function () {
                callback();
            });
        }
        else {
            setTimeout(function () {
                callback();
            }, 50);
        }
    };
    CODOM.createElementFromHTML = function (htmlString, toAppend) {
        if (toAppend === void 0) { toAppend = null; }
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();
        if (toAppend) {
            Array.prototype.slice.call(div.children).forEach(function (el) {
                toAppend.appendChild(el);
            });
        }
        else {
            return div.firstChild;
        }
    };
    CODOM.onElementAppear = function (parent, query, callback, i) {
        if (i === void 0) { i = 200; }
        if (parent.querySelector(query) == null) {
            if (i <= 0) {
                return;
            }
            setTimeout(function () {
                CODOM.onElementAppear(parent, query, callback, --i);
            }, 10);
            return;
        }
        else {
            callback(parent.querySelector(query));
        }
    };
    CODOM.insertHTML = function (el, html) {
        el.innerHTML = html;
        var codes = el.getElementsByTagName("script");
        for (var i = 0; i < codes.length; i++) {
            eval(codes[i].text);
        }
    };
    CODOM.getElementPosition = function (elem) {
        if (elem === void 0) { elem = null; }
        if (elem == null) {
            return 0;
        }
        var location = 0;
        if (elem.offsetParent) {
            do {
                location += elem.offsetTop;
                elem = elem.offsetParent;
            } while (elem);
        }
        return location >= 0 ? location : 0;
    };
    return CODOM;
}());
exports.CODOM = CODOM;
