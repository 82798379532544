"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var server_response_handler_1 = require("../../../../../../../controller/static/ts/server-response-handler");
var SiteResponseHandlerFunctions = (function (_super) {
    __extends(SiteResponseHandlerFunctions, _super);
    function SiteResponseHandlerFunctions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SiteResponseHandlerFunctions.prototype.handleRedirectField = function (field) {
        return false;
    };
    SiteResponseHandlerFunctions.prototype.addFieldResponse = function (field, form) {
        if (field == null || field.name == null) {
            return;
        }
        field.name = field.name.replace(/\[/g, '\\[');
        field.name = field.name.replace(/\]/g, '\\]');
        field.name = field.name.replace(/\./g, '\\.');
        if (form) {
            var el = form.querySelectorAll('[data-field-response-block=' + field.name + ']')[field.index];
            if (el) {
                el.classList.add('error');
                if (field.message) {
                    el.setAttribute('data-message', field.message);
                }
            }
        }
    };
    SiteResponseHandlerFunctions.prototype.onError = function () {
    };
    SiteResponseHandlerFunctions.prototype.onSuccess = function () {
    };
    SiteResponseHandlerFunctions.prototype.removeFieldResponses = function (form) {
        if (form == null) {
            return;
        }
        var els = form.querySelectorAll('[data-field-response-block]');
        for (var _i = 0, _a = Array.prototype.slice.call(els); _i < _a.length; _i++) {
            var el = _a[_i];
            el.classList.remove('error');
            el.classList.remove('success');
            el.removeAttribute('data-message');
        }
    };
    SiteResponseHandlerFunctions.prototype.removeGlobalResponse = function (form) {
    };
    SiteResponseHandlerFunctions.prototype.showGlobalResponse = function (field) {
        if (field.type == 'success') {
            $.toast({
                text: field.message,
                hideAfter: 3000,
                position: 'mid-center',
                icon: "success",
                heading: ''
            });
        }
        else {
            $.toast({
                text: field.message,
                hideAfter: 3000,
                position: 'mid-center',
                icon: "error",
                heading: ''
            });
        }
    };
    SiteResponseHandlerFunctions.prototype.handleHTMLField = function (field) {
        return false;
    };
    SiteResponseHandlerFunctions.prototype.onComplete = function () {
    };
    return SiteResponseHandlerFunctions;
}(server_response_handler_1.ServerResponse.HandlerFunctions));
exports.SiteResponseHandlerFunctions = SiteResponseHandlerFunctions;
