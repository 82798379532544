"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var custom_elements_1 = require("./custom-elements");
var COCheckBox = (function (_super) {
    __extends(COCheckBox, _super);
    function COCheckBox(element) {
        var _this = _super.call(this, element) || this;
        _this._onChange = null;
        _this.createText();
        _this.createOuterLabel();
        _this.createInput();
        _this.createResponseContainer();
        _this.initOnClick();
        return _this;
    }
    COCheckBox.prototype.handleEvent = function (evt) {
        switch (evt.type) {
            case 'change':
                this.clickEvent();
        }
    };
    COCheckBox.prototype.clickEvent = function () {
        var checkbox = this.getElement().querySelector('input[type=checkbox]');
    };
    COCheckBox.prototype.createOuterLabel = function () {
        this.outerLabel = document.createElement('label');
        this.element.appendChild(this.outerLabel);
    };
    COCheckBox.prototype.initOnClick = function () {
    };
    COCheckBox.prototype.createText = function () {
        this.textContainer = document.createElement('div');
        this.textContainer.classList.add('text-container');
        if (this.element.childNodes.length > 0) {
            this.text = document.createElement('div');
            while (this.element.childNodes.length > 0) {
                this.text.appendChild(this.element.childNodes[0]);
            }
            this.text.classList.add('text');
            this.textContainer.appendChild(this.text);
        }
    };
    COCheckBox.prototype.createInput = function () {
        this.input = document.createElement('input');
        this.input.type = 'checkbox';
        this.input.name = this.element.getAttribute('name');
        this.outerLabel.appendChild(this.input);
        if (this.element.hasAttribute('value')) {
            this.input.setAttribute('value', this.element.getAttribute('value'));
        }
        if (this.element.hasAttribute('checked')) {
            this.input.setAttribute('checked', 'checked');
        }
        this.outerLabel.appendChild(this.textContainer);
        this.element.setAttribute('data-field-response-block', this.element.getAttribute('name'));
        this.element.removeAttribute('name');
        this.element.removeAttribute('value');
        if (this.element.hasAttribute('onchange')) {
            var func = void 0;
            var str = "func = function() {" + this.element.getAttribute('onchange') + "};";
            eval(str);
            this.input.onchange = func;
            this.element.removeAttribute('onchange');
        }
    };
    COCheckBox.prototype.createResponseContainer = function () {
        var responseContainer = document.createElement('div');
        responseContainer.classList.add('response');
        this.element.appendChild(responseContainer);
    };
    COCheckBox.prototype.onAttributeChange = function () {
        if (this.element.hasAttribute('data-message') && this.element.getAttribute('data-message')) {
            this.element.querySelector('.response').innerHTML = this.element.getAttribute('data-message');
        }
        else {
            var response = this.element.querySelector('.response').innerHTML = '';
        }
    };
    COCheckBox.prototype.onAdded = function () {
    };
    return COCheckBox;
}(custom_elements_1.CustomElements.CustomElementBase));
exports.COCheckBox = COCheckBox;
