"use strict";
exports.__esModule = true;
var COGenerator;
(function (COGenerator) {
    var UniqueID = (function () {
        function UniqueID() {
        }
        UniqueID.create = function () {
            this.counter++;
            return 'UID__' + this.counter;
        };
        UniqueID.counter = 0;
        return UniqueID;
    }());
    COGenerator.UniqueID = UniqueID;
})(COGenerator = exports.COGenerator || (exports.COGenerator = {}));
