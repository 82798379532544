"use strict";
exports.__esModule = true;
var COAjax;
(function (COAjax) {
    var Response = (function () {
        function Response() {
            this.onShowGlobal = function () {
            };
            this.onDismissGlobal = function () {
            };
            this.onSuccess = function () {
            };
            this.onError = function () {
            };
        }
        Response.prototype.execute = function () {
            this.removeFieldResponses();
            this.addFieldResponses();
            if (this.response.global != null) {
                this.onShowGlobal(this.response.global);
            }
            if (this.response.ok === true) {
                this.onSuccess.call(this);
            }
            else {
                this.onError.call(this);
            }
        };
        Response.prototype.addFieldResponses = function () {
            if (this.fieldAddResponseCallback != null) {
                for (var _i = 0, _a = this.response.fields; _i < _a.length; _i++) {
                    var field = _a[_i];
                    this.fieldAddResponseCallback.bind(field)();
                }
            }
        };
        Response.prototype.removeFieldResponses = function () {
            if (this.fieldClearResponseCallback != null) {
                this.fieldClearResponseCallback();
            }
        };
        Response.prototype.setFieldAddResponse = function (callback) {
            this.fieldAddResponseCallback = callback;
        };
        Response.prototype.setFieldClearResponse = function (callback) {
            this.fieldClearResponseCallback = callback;
        };
        Response.prototype.isOk = function () {
            return this.response.ok;
        };
        Response.prototype.getField = function (name) {
            for (var _i = 0, _a = this.response.fields; _i < _a.length; _i++) {
                var field = _a[_i];
                if (field.name === name) {
                    return field;
                }
            }
            return null;
        };
        Response.prototype.getGlobal = function () {
            return this.response.global;
        };
        Response.prototype.setForm = function (form) {
            this.form = form;
        };
        Response.prototype.setOnSuccess = function (callback) {
            this.onSuccess = callback;
        };
        Response.prototype.getForm = function () {
            return this.form;
        };
        Response.prototype.setOnError = function (callback) {
            this.onError = callback;
        };
        Response.prototype.setOnDismissGlobal = function (callback) {
            this.onDismissGlobal = callback;
        };
        Response.prototype.setOnShowGlobal = function (callback) {
            this.onShowGlobal = callback;
        };
        Response.prototype.setResponse = function (response) {
            this.response = new COAjax.Response.Data();
            this.response.ok = response.ok;
            if (response.global != null) {
                var f = new COAjax.Response.Data.Field();
                f.type = response.global.type;
                f.message = response.global.message;
                this.response.global = f;
            }
            else {
                this.response.global = null;
            }
            if (response.fields == undefined) {
                return;
            }
            for (var _i = 0, _a = response.fields; _i < _a.length; _i++) {
                var field = _a[_i];
                var f = new COAjax.Response.Data.Field();
                f.name = field.name;
                f.type = field.type;
                f.show = field.show;
                f.message = field.message;
                f.index = field.index;
                this.response.fields.push(f);
            }
        };
        return Response;
    }());
    COAjax.Response = Response;
    (function (Response) {
        var Data = (function () {
            function Data() {
                this.fields = [];
            }
            return Data;
        }());
        Response.Data = Data;
        (function (Data) {
            var Field = (function () {
                function Field() {
                    this.index = 0;
                }
                return Field;
            }());
            Data.Field = Field;
        })(Data = Response.Data || (Response.Data = {}));
    })(Response = COAjax.Response || (COAjax.Response = {}));
})(COAjax = exports.COAjax || (exports.COAjax = {}));
