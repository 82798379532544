"use strict";
exports.__esModule = true;
var server_response_handler_1 = require("./server-response-handler");
var form_1 = require("./form");
var ServerCallType;
(function (ServerCallType) {
    ServerCallType["GET"] = "get";
    ServerCallType["POST"] = "post";
})(ServerCallType = exports.ServerCallType || (exports.ServerCallType = {}));
var ServerCallDataType;
(function (ServerCallDataType) {
    ServerCallDataType["JSON"] = "json";
    ServerCallDataType["TEXT"] = "text";
})(ServerCallDataType = exports.ServerCallDataType || (exports.ServerCallDataType = {}));
var Server = (function () {
    function Server() {
    }
    Server.setServerConfig = function (serverConfig) {
        this.serverConfig = serverConfig;
    };
    Server.getServerConfig = function () {
        return this.serverConfig;
    };
    Server.call = function (config) {
        if (this.serverConfig && this.serverConfig.onBeforeCall) {
            this.serverConfig.onBeforeCall(config);
        }
        if (config.form) {
            var formdata = $(config.form).serialize();
            if (config.data && $.param(config.data).length) {
                config.data = formdata + '&' + $.param(config.data);
            }
            else {
                config.data = formdata;
            }
        }
        if (config.form != null) {
            form_1.Form.disable(config.form);
            server_response_handler_1.ServerResponse.Handler.getHandlerFunctions().removeFieldResponses(config.form);
            server_response_handler_1.ServerResponse.Handler.getHandlerFunctions().removeGlobalResponse(config.form);
        }
        if (config.type == null) {
            config.type = ServerCallType.POST;
        }
        if (config.dataType == null) {
            config.dataType = ServerCallDataType.JSON;
        }
        $.ajax({
            url: config.url,
            type: config.type,
            dataType: config.dataType,
            data: config.data,
            success: function (responseText) {
                if (config.dataType == ServerCallDataType.TEXT) {
                    config.onFinish(responseText);
                    return;
                }
                if (Server.serverConfig && Server.serverConfig.onCallFinished) {
                    Server.serverConfig.onCallFinished(config);
                }
                var response = new server_response_handler_1.ServerResponse.Handler({ data: responseText, form: config.form }).execute();
                if (config.form != null) {
                    form_1.Form.enable(config.form);
                }
                if (config.onFinish) {
                    config.onFinish(response);
                }
            },
            error: function (xhr) {
                if (config.onError) {
                    config.onError(xhr.responseText);
                }
                if (Server.serverConfig && Server.serverConfig.onError) {
                    Server.serverConfig.onError(xhr, config);
                }
            }
        });
    };
    return Server;
}());
exports.Server = Server;
