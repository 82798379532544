"use strict";
exports.__esModule = true;
var COG = require("./co.generator");
var COGenerator = COG.CO.Generator;
var CustomElements;
(function (CustomElements) {
    var Controller = (function () {
        function Controller() {
        }
        Controller.startMutationObserver = function () {
            var body = document.body;
            var that = this;
            var config = { childList: true, subtree: true, attributes: true };
            this.mutationObserver = new MutationObserver(function (mutationsList) {
                for (var _i = 0, mutationsList_1 = mutationsList; _i < mutationsList_1.length; _i++) {
                    var mutation = mutationsList_1[_i];
                    if (mutation.type == 'attributes') {
                        if (mutation.target.attributes.getNamedItem('data-co-custom-element-uid')) {
                            var ce = that.getInstanceByUID(mutation.target.attributes.getNamedItem('data-co-custom-element-uid').value);
                            if (ce) {
                                if (ce.getElement()) {
                                    ce.onAttributeChange();
                                }
                            }
                        }
                    }
                }
                Controller.findNewTags();
            });
            this.mutationObserver.observe(document, config);
        };
        Controller.getInstance = function (el) {
            for (var _i = 0, _a = this.tags; _i < _a.length; _i++) {
                var tag = _a[_i];
                if (tag.id == el.getAttribute('data-co-custom-element-uid')) {
                    return tag.instance;
                }
            }
            return null;
        };
        Controller.getInstanceByUID = function (uid) {
            for (var _i = 0, _a = this.tags; _i < _a.length; _i++) {
                var tag = _a[_i];
                if (tag.id == uid) {
                    tag.instance.element = document.querySelector('[data-co-custom-element-uid=' + uid + ']');
                    return tag.instance;
                }
            }
            return null;
        };
        Controller.findNewTags = function () {
            var that = this;
            var names = [];
            for (var _i = 0, _a = this.customElementTags; _i < _a.length; _i++) {
                var ce = _a[_i];
                names.push(ce.tagName);
            }
            [].forEach.call(document.querySelectorAll(names.join(',')), function (el) {
                if (that.getInstance(el) == null) {
                    var uid = COGenerator.UniqueID.create();
                    var clname = that.getClassname(el.tagName);
                    el.setAttribute('data-co-custom-element-uid', uid);
                    var obj = new clname(el);
                    obj.onAdded();
                    that.tags.push(new CustomElementObject(uid, obj));
                }
            });
        };
        Controller.getClassname = function (tagname) {
            for (var _i = 0, _a = this.customElementTags; _i < _a.length; _i++) {
                var i = _a[_i];
                if (i.tagName.toLowerCase() == tagname.toLowerCase()) {
                    return i.className;
                }
            }
            return null;
        };
        Controller.add = function (tagName, className) {
            this.customElementTags.push(new CustomElementTag(tagName, className));
            if (this.mutationObserver) {
                this.findNewTags();
            }
            else {
                if (this.mutationObserverLoading == false) {
                    this.startMutationObserver();
                }
            }
        };
        Controller.tags = [];
        Controller.customElementTags = [];
        Controller.mutationObserver = null;
        Controller.mutationObserverLoading = false;
        return Controller;
    }());
    CustomElements.Controller = Controller;
    var CustomElementBase = (function () {
        function CustomElementBase(element) {
            this.element = null;
            this.element = element;
        }
        CustomElementBase.prototype.setElement = function (element) {
            this.element = element;
        };
        CustomElementBase.prototype.getElement = function () {
            return this.element;
        };
        return CustomElementBase;
    }());
    CustomElements.CustomElementBase = CustomElementBase;
    var CustomElementTag = (function () {
        function CustomElementTag(tagName, className) {
            this.tagName = tagName;
            this.className = className;
        }
        return CustomElementTag;
    }());
    CustomElements.CustomElementTag = CustomElementTag;
    var CustomElementObject = (function () {
        function CustomElementObject(id, instance) {
            this.id = id;
            this.instance = instance;
        }
        return CustomElementObject;
    }());
    CustomElements.CustomElementObject = CustomElementObject;
})(CustomElements = exports.CustomElements || (exports.CustomElements = {}));
