"use strict";
exports.__esModule = true;
var server_1 = require("../../../../../../../controller/static/ts/server");
var Webapp = (function () {
    function Webapp() {
    }
    Webapp.updateUnitDriver = function (form) {
        var select = form.querySelector("select[name='DriverId']");
        var name = select.options[select.selectedIndex].innerHTML;
        server_1.Server.call({
            url: '/page/set-unit-driver',
            data: { name: name },
            form: form,
            method: 'post',
            dataType: "json",
            onFinish: function (response) {
                if (response.isOk()) {
                    var UnitId = response.getField('UnitId').message;
                    var qs = "#service-unit-list .header[data-id='" + UnitId + "'] .DriverName";
                    var DriverName = document.querySelector(qs);
                    DriverName.innerHTML = name;
                    closeWindow();
                }
            }
        });
    };
    Webapp.addUnitDriver = function (form) {
        server_1.Server.call({
            url: '/page/add-unit-driver',
            form: form,
            method: 'post',
            dataType: "json",
            onFinish: function (response) {
                if (response.isOk()) {
                    var UnitId = response.getField('UnitId').message;
                    var qs = "#service-unit-list .header[data-id='" + UnitId + "'] .DriverName";
                    var DriverName = document.querySelector(qs);
                    DriverName.innerHTML = response.getField('drivername').message;
                    closeWindow();
                }
            }
        });
    };
    Webapp.openNewDriverWindow = function (UnitId) {
        closeWindow();
        createWindow({
            title: 'Új sofőr hozzáadása',
            data: {
                UnitId: UnitId
            },
            url: 'page/window-new-driver'
        });
    };
    return Webapp;
}());
exports.Webapp = Webapp;
