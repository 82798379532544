"use strict";
exports.__esModule = true;
var server_1 = require("./server");
var new_co_dom_1 = require("./new-co.dom");
var COURLNavigator = (function () {
    function COURLNavigator() {
        this.popListenerAdded = false;
    }
    COURLNavigator.init = function (config) {
        if (config === void 0) { config = null; }
        if (config == null) {
            config = {};
        }
        ;
        COURLNavigator.config = config;
        this.addPopStateListener();
        this.scrollAfterLoad();
        this.addClickListenerToLinks();
        this.addMutationObserver();
    };
    COURLNavigator.addPopStateListener = function () {
        var that = this;
        history.scrollRestoration = 'manual';
        this.urlTo = window.location.pathname + window.location.search;
        window.addEventListener('popstate', function (event) {
            COURLNavigator.scrollYAfterNavigation = event.state ? event.state.scrollTop : null;
            that.load(location.pathname + location.search, false, COURLEventOrigin.onclick, 0, event.state == null ? COURLNavigatorDirection.forward : COURLNavigatorDirection.backward);
        });
    };
    COURLNavigator.addMutationObserver = function () {
        var config = { childList: true, subtree: true, attributes: false };
        COURLNavigator.mutationObserver = new MutationObserver(function (mutationsList) {
            COURLNavigator.mutationObserver.disconnect();
            COURLNavigator.addClickListenerToLinks();
            COURLNavigator.addMutationObserver();
        });
        this.mutationObserver.observe(document, config);
    };
    COURLNavigator.scrollAfterLoad = function () {
        if (this.scrollYAfterNavigation) {
            $('html, body').animate({ scrollTop: this.scrollYAfterNavigation }, 100);
            this.scrollYAfterNavigation = null;
            return;
        }
        var that = this;
        var hash = window.location.hash;
        if (this.hashHandled) {
            return;
        }
        this.hashHandled = true;
        if (hash.length != undefined && hash.length > 0) {
            COURLNavigator.scrollToBlock(hash.substr(1));
        }
        else {
            if (this.config.scrollToBlockAfterNavigation) {
                COURLNavigator.scrollToBlock(this.config.scrollToBlockAfterNavigation());
            }
        }
    };
    COURLNavigator.addClickListenerToLinks = function () {
        if (this.config.linkQuerySelector == null) {
            this.config.linkQuerySelector = 'a.ajax:not(.urlnavigator-initialized)';
        }
        document.querySelectorAll(this.config.linkQuerySelector).forEach(function (el) {
            COURLNavigator.addClickListenerToLink(el);
        });
    };
    COURLNavigator.setURL = function (url) {
        history.replaceState(null, null, url);
    };
    COURLNavigator.addClickListenerToLink = function (el) {
        var that = this;
        if (el.classList.contains('urlnavigator-initialized')) {
            return;
        }
        if (el.getAttribute('onclick') === null || el.getAttribute('onclick').trim() == '') {
            el.classList.add('urlnavigator-initialized');
            el.addEventListener('click', function (event) {
                if (COURLNavigator.config.onClick) {
                    COURLNavigator.config.onClick(el);
                }
                if (el.getAttribute('href') !== null
                    && ((el.getAttribute('href').indexOf('http://') === -1
                        &&
                            el.getAttribute('href').indexOf('https://') === -1)
                        || (el.getAttribute('href').indexOf('http://') !== -1
                            && el.getAttribute('href').indexOf('http://' + window.location.host) !== -1))
                    && (el.getAttribute('href').indexOf('/data/') !== 0)) {
                    event.preventDefault();
                    var to = el.getAttribute('href');
                    if (to.indexOf('#') == 0) {
                        to = to.substr(1);
                        history.pushState(null, null, el.getAttribute('href'));
                        COURLNavigator.scrollToBlock(to);
                        return false;
                    }
                    if (to.indexOf('#') !== -1) {
                        var path = to.substr(0, to.indexOf('#'));
                        if (window.location.pathname === path) {
                            history.pushState(null, null, $(this).attr('href'));
                            COURLNavigator.scrollToBlock(to.substr(to.indexOf('#') + 1));
                            return false;
                        }
                    }
                    else {
                        var cl = window.location.pathname + window.location.search;
                        if (cl === to) {
                            if (COURLNavigator.config.scrollToBlockAfterNavigation) {
                                COURLNavigator.scrollToBlock(COURLNavigator.config.scrollToBlockAfterNavigation());
                            }
                            COURLNavigator.setURL(window.location.pathname);
                            return false;
                        }
                    }
                    if (el.getAttribute('target') == '_blank') {
                        return true;
                    }
                    var url = el.getAttribute('href');
                    COURLNavigator.load(url, true, COURLEventOrigin.onclick);
                    return false;
                }
                else {
                    return true;
                }
            });
        }
    };
    COURLNavigator.load = function (url, addToHistory, eventOrigin, scrollTo, direction) {
        if (addToHistory === void 0) { addToHistory = true; }
        if (eventOrigin === void 0) { eventOrigin = COURLEventOrigin.script; }
        if (scrollTo === void 0) { scrollTo = 0; }
        if (direction === void 0) { direction = COURLNavigatorDirection.forward; }
        var that = this;
        if (COURLNavigator.config.onBeforeLoad != null) {
            COURLNavigator.config.onBeforeLoad();
        }
        COURLNavigator.lastEventOrigin = eventOrigin;
        this.urlTo = url;
        COURLNavigator.urlFrom = window.location.pathname + window.location.search;
        server_1.Server.call({
            url: url,
            onError: function () {
                if (COURLNavigator.config.onLoadError != null) {
                    COURLNavigator.config.onLoadError();
                }
            },
            onFinish: function (response) {
                var scrollTop = $(window).scrollTop();
                that.hashHandled = false;
                if (COURLNavigator.config.onLoaded == null) {
                    return;
                }
                COURLNavigator.config.onLoaded(response.config.data, direction);
                if (addToHistory == true) {
                    history.replaceState({ scrollTop: scrollTop }, document.title, location.href);
                    history.pushState(null, document.title, url);
                }
            }
        });
    };
    COURLNavigator.getLastEventOrigin = function () {
        return COURLNavigator.lastEventOrigin;
    };
    COURLNavigator.scrollToBlock = function (blockName, calculator) {
        if (calculator === void 0) { calculator = null; }
        if (blockName.indexOf('#') !== -1) {
            blockName = blockName.substr(blockName.indexOf('#') + 1);
        }
        var found = null;
        Array.prototype.slice.call(document.querySelectorAll('[data-block]')).forEach(function (el) {
            if (el.hasAttribute('data-block')) {
                var names = el.getAttribute('data-block');
                var namesArray = names.split('|');
                namesArray.forEach(function (n) {
                    if (n == blockName) {
                        found = el;
                    }
                });
            }
        });
        if (found) {
            COURLNavigator.scrollToElement(found, calculator);
        }
    };
    COURLNavigator.scrollToElement = function (el, secondCalculator, speed) {
        if (secondCalculator === void 0) { secondCalculator = null; }
        if (speed === void 0) { speed = 300; }
        if (speed == 0) {
            $('html,body').scrollTop(new_co_dom_1.CODOM.getElementPosition(el) - COURLNavigator.config.scrollShiftCalculator.calculate() + secondCalculator.calculate());
            return;
        }
        var page = $('html,body');
        page.animate({
            scrollTop: new_co_dom_1.CODOM.getElementPosition(el) - (COURLNavigator.config.scrollShiftCalculator != null ? COURLNavigator.config.scrollShiftCalculator.calculate() : 0) + (secondCalculator != null ? secondCalculator.calculate() : 0)
        }, speed, function () {
        });
        setTimeout(function () {
            page.stop();
        }, speed + 100);
        return true;
    };
    COURLNavigator.hashHandled = false;
    COURLNavigator.config = {};
    COURLNavigator.scrollYAfterNavigation = null;
    return COURLNavigator;
}());
exports.COURLNavigator = COURLNavigator;
var COURLNavigatorDirection;
(function (COURLNavigatorDirection) {
    COURLNavigatorDirection[COURLNavigatorDirection["forward"] = 0] = "forward";
    COURLNavigatorDirection[COURLNavigatorDirection["backward"] = 1] = "backward";
})(COURLNavigatorDirection = exports.COURLNavigatorDirection || (exports.COURLNavigatorDirection = {}));
var COURLEventOrigin;
(function (COURLEventOrigin) {
    COURLEventOrigin[COURLEventOrigin["script"] = 0] = "script";
    COURLEventOrigin[COURLEventOrigin["onclick"] = 1] = "onclick";
})(COURLEventOrigin = exports.COURLEventOrigin || (exports.COURLEventOrigin = {}));
