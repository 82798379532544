"use strict";
exports.__esModule = true;
var co_ajax_1 = require("./co.ajax");
var COD = require("./co.dom");
var CODOM = COD.CO.DOM;
var COW = require("./co.window");
var COWindow = COW.CO.Window;
var co_urlnavigator_1 = require("./co.urlnavigator");
var Form = (function () {
    function Form() {
    }
    Form.setUseURLNavigatorOnRedirect = function (v) {
        this.useURLNavigatorOnRedirect = v;
    };
    Form.getUseURLNavigatorOnRedirect = function () {
        return this.useURLNavigatorOnRedirect;
    };
    Form.setGlobalHandler = function (callback) {
        this.globalHandler = callback;
    };
    Form.handleResponse = function (response, form) {
        if (form === void 0) { form = null; }
        var that = this;
        var r = new co_ajax_1.COAjax.Response();
        r.setResponse(response);
        if (r.getGlobal()) {
        }
        response.fields.forEach(function (field) {
            if (field.type == 'html') {
                if ($('#' + field.name)) {
                    $('#' + field.name).html(field.message);
                }
            }
            if (field.type == 'redirect') {
                if (Form.getUseURLNavigatorOnRedirect()) {
                    co_urlnavigator_1.COURLNavigator.Controller.loadContent(field.message);
                }
                else {
                    window.location.href = field.message;
                }
            }
            if (field.type == 'close_window') {
                COWindow.closeAll();
            }
        });
        if (form != null) {
            r.setForm(form);
            r.setFieldAddResponse(function () {
                if (this.name == null) {
                    return;
                }
                this.name = this.name.replace(/\[/g, '\\[');
                this.name = this.name.replace(/\]/g, '\\]');
                this.name = this.name.replace(/\./g, '\\.');
                var el = form.querySelectorAll('[data-field-response-block=' + this.name + ']')[this.index];
                if (el) {
                    el.classList.add('error');
                    if (this.message) {
                        el.setAttribute('data-message', this.message);
                    }
                }
            });
            r.setFieldClearResponse(function () {
                Form.clearResponse(form);
            });
            Form.enableForm(form);
        }
        r.setOnSuccess(function () {
            if (response.global && response.global.message) {
                if (Form.globalHandler) {
                    Form.globalHandler(response.global);
                }
            }
            if (r.isOk() == false) {
                r.getForm().classList.add('changed');
            }
            if (r.getField('redirect') != null) {
                if (Form.getUseURLNavigatorOnRedirect()) {
                    co_urlnavigator_1.COURLNavigator.Controller.loadContent(r.getField('redirect').message);
                }
                else {
                    window.location.href = r.getField('redirect').message;
                }
            }
            if (r.getField('reload') != null) {
                window.location.reload();
            }
        });
        r.setOnError(function () {
            if (response.global && response.global.message) {
                if (Form.globalHandler) {
                    Form.globalHandler(response.global);
                }
            }
        });
        r.execute();
        return r;
    };
    Form.disable = function (form) {
        return this.disableForm(form);
    };
    Form.disableForm = function (form) {
        var submit = form.querySelector('input[type=submit]');
        if (submit) {
            submit.classList.add('loading');
            submit.setAttribute('disabled', 'disabled');
        }
    };
    Form.enable = function (form) {
        return this.enableForm(form);
    };
    Form.enableForm = function (form) {
        form.classList.remove('changed');
        var submit = form.querySelector('[type=submit]');
        if (submit) {
            submit.removeAttribute('disabled');
            submit.classList.remove('loading');
            submit.style.width = null;
        }
    };
    Form.clearResponse = function (form) {
        if (form === void 0) { form = null; }
        if (form == null) {
            return;
        }
        var els = form.querySelectorAll('[data-field-response-block]');
        for (var _i = 0, _a = Array.prototype.slice.call(els); _i < _a.length; _i++) {
            var el = _a[_i];
            el.classList.remove('error');
            el.classList.remove('success');
            el.removeAttribute('data-message');
        }
    };
    Form.send = function (url, d, onFinished) {
        if (d === void 0) { d = null; }
        if (onFinished === void 0) { onFinished = null; }
        var form;
        var data;
        var formData;
        if (d instanceof HTMLFormElement) {
            form = d;
            formData = new FormData(d);
        }
        else {
            formData = object;
        }
        if (form != null) {
            Form.disableForm(form);
            Form.clearResponse(form);
        }
        var request = new XMLHttpRequest();
        request.open("POST", url);
        request.onreadystatechange = function () {
            if (request.readyState === 4 && request.status === 200) {
                if (form != null) {
                    Form.enableForm(form);
                }
                data = JSON.parse(request.responseText);
                var response = Form.handleResponse(data, form);
                if (onFinished) {
                    onFinished(response, form);
                }
            }
        };
        request.send(formData);
        return;
        $.ajax({
            url: url,
            type: 'post',
            contentType: 'multipart/form-data',
            dataType: "json",
            processData: false,
            data: formData,
            success: function (data) {
                var response = Form.handleResponse(data, form);
                if (form != null) {
                    Form.enableForm(form);
                }
                if (onFinished) {
                    onFinished(response, form);
                }
            }
        });
    };
    Form.watchChanges = function () {
        var obs = new MutationObserver(function (mutations, observer) {
            for (var i = 0; i < mutations.length; ++i) {
                for (var j = 0; j < mutations[i].addedNodes.length; ++j) {
                    if (mutations[i].addedNodes[j].parentNode) {
                        mutations[i].addedNodes[j].parentNode.querySelectorAll('form').forEach(function (form) {
                            if (form.classList.contains('watching-changes') == false) {
                                setTimeout(function () {
                                    form.addEventListener('input', function () {
                                        form.classList.add('changed');
                                    });
                                }, 500);
                            }
                        });
                    }
                }
            }
        });
        CODOM.ready(function () {
            document.querySelectorAll('form').forEach(function (el) {
                el.classList.add('watching-changes');
                el.addEventListener('input', function () {
                    el.classList.add('changed');
                });
            });
            obs.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    };
    Form.useURLNavigatorOnRedirect = false;
    Form.globalHandler = null;
    return Form;
}());
exports.Form = Form;
