"use strict";
exports.__esModule = true;
var co_input_1 = require("../../../../../../controller/static/ts/co-input");
var custom_elements_1 = require("../../../../../../controller/static/ts/custom-elements");
var webapp_1 = require("./components/webapp");
exports.Webapp = webapp_1.Webapp;
var server_response_handler_1 = require("../../../../../../controller/static/ts/server-response-handler");
var ResponseHandlerFunctions_1 = require("./components/ResponseHandlerFunctions");
var server_1 = require("../../../../../../controller/static/ts/server");
exports.Server = server_1.Server;
var co_checkbox_1 = require("../../../../../../controller/static/ts/co-checkbox");
custom_elements_1.CustomElements.Controller.add('co-input', co_input_1.COInput);
custom_elements_1.CustomElements.Controller.add('co-checkbox', co_checkbox_1.COCheckBox);
server_response_handler_1.ServerResponse.Handler.setHandlerFunctions(new ResponseHandlerFunctions_1.SiteResponseHandlerFunctions());
server_1.Server.setServerConfig({
    onBeforeCall: function (p1) {
    },
    onCallFinished: function (p1) {
    },
    onError: function (p1, p2) {
    }
});
